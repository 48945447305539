import './FiFurnitureTypeSelectionDialog.scss';
import { FiFurnitureService } from '../fiFurniture/FiFurnitureService';

import * as angular from 'angular';

import { Component } from '../Component';

import lightGallery from "lightgallery";
import lgThumbnail from "lightgallery/plugins/thumbnail";

@Component({
    controllerAs: 'vm',
    template: require('./FiFurnitureTypeSelectionDialog.html'),
    bindings: {

    },
})
export class FiFurnitureTypeSelectionDialog {
    static $inject = [
        '$mdDialog',
        'fiFurnitureService',
        '$timeout',
        '$http',
    ];

    firstStep: boolean = true;
    environmentSelected: boolean = false;

    constructor(
        private $mdDialog: angular.material.IDialogService,
        private fs: FiFurnitureService,
        private $timeout: angular.ITimeoutService,
        private $http: angular.IHttpService,
    ) {
        //
    }

    gallery = [];
    showGalleryButton: boolean = false;
    dynamicGalleryType1 = [];
    dynamicGalleryType2 = [];
    dynamicGalleryType3 = [];
    dynamicGalleryType4 = [];
    dynamicGalleryType5 = [];
    dynamicGalleryType6 = [];

    $onInit() {
        this.fs.environment = 2;

        this.$http.get('https://www.fitit.lt/db/admin-gallery.php').then((data) => {
            this.gallery.push(data);
            for(const row of this.gallery[0].data) {
                if (row.type == 1) {
                    this.dynamicGalleryType1.push(
                        {
                            src: 'https://www.fitit.lt/gallery/' + row.image_1,
                            thumb: 'https://www.fitit.lt/gallery/' + row.image_1,
                            subHtml: '',
                        }
                    )
                }
                else if (row.type == 2) {
                    this.dynamicGalleryType2.push(
                        {
                            src: 'https://www.fitit.lt/gallery/' + row.image_1,
                            thumb: 'https://www.fitit.lt/gallery/' + row.image_1,
                            subHtml: '',
                        }
                    )
                }
                else if (row.type == 3) {
                    this.dynamicGalleryType3.push(
                        {
                            src: 'https://www.fitit.lt/gallery/' + row.image_1,
                            thumb: 'https://www.fitit.lt/gallery/' + row.image_1,
                            subHtml: '',
                        }
                    )
                }
                else if (row.type == 4) {
                    this.dynamicGalleryType4.push(
                        {
                            src: 'https://www.fitit.lt/gallery/' + row.image_1,
                            thumb: 'https://www.fitit.lt/gallery/' + row.image_1,
                            subHtml: '',
                        }
                    )
                }
                else if (row.type == 5) {
                    this.dynamicGalleryType5.push(
                        {
                            src: 'https://www.fitit.lt/gallery/' + row.image_1,
                            thumb: 'https://www.fitit.lt/gallery/' + row.image_1,
                            subHtml: '',
                        }
                    )
                }
                else if (row.type == 6) {
                    this.dynamicGalleryType6.push(
                        {
                            src: 'https://www.fitit.lt/gallery/' + row.image_1,
                            thumb: 'https://www.fitit.lt/gallery/' + row.image_1,
                            subHtml: '',
                        }
                    )
                }
            }

        });
    }

    returnCorrectTypeForGallery() {
        if (this.fs.furniture.type == 1) {
            return this.dynamicGalleryType1;
        }
        else if (this.fs.furniture.type == 2) {
            return this.dynamicGalleryType2;
        }
        else if (this.fs.furniture.type == 3) {
            return this.dynamicGalleryType3;
        }
        else if (this.fs.furniture.type == 4) {
            return this.dynamicGalleryType4;
        }
        else if (this.fs.furniture.type == 5) {
            return this.dynamicGalleryType5;
        }
        else if (this.fs.furniture.type == 6) {
            return this.dynamicGalleryType6;
        }
    }

    getTypeName() {
        if (this.fs.furniture.type == 1) {
            return 'Spinta su varstomomis durimis'
        }
        else if (this.fs.furniture.type == 2) {
            return 'Spinta su stumdomis durimis';
        }
        else if (this.fs.furniture.type == 3) {
            return 'Komoda';
        }
        else if (this.fs.furniture.type == 4) {
            return 'Lentyna';
        }
        else if (this.fs.furniture.type == 5) {
            return 'TV spintelė';
        }
        else if (this.fs.furniture.type == 6) {
            return 'Spintelė';
        }
    }

    openGallery() {
        const $dynamicGallery = document.getElementById('dynamic-gallery');
        const dynamicGallery = lightGallery($dynamicGallery, {
            dynamic: true,
            download : false,
            plugins: [lgThumbnail],
            dynamicEl: this.returnCorrectTypeForGallery(),
        });

        dynamicGallery.openGallery();
    }

    continue() {
        this.firstStep = false;
    }

    selectEnvironment(type: number) {
        if (type == 1) {
            this.fs.furniture.collect_myself = true;
            this.fs.environment = 1;
        }
        else if (type == 2) {
            this.fs.furniture.collect_myself = false;
            this.fs.environment = 2;
        }
    }

    showExamples() {

    }

    goNext() {
        this.environmentSelected = true;
    }

    selectPlinth(type: number) {

        this.fs.furniture.plinth.type = type;

        if (type == 1) { 
            this.fs.furniture.plinth.inner_height = 10;
            this.fs.furniture.plinth.height = 10 + this.fs.edgeWidth;
        }
        else if (type == 2) {
            // this.fs.furniture.plinth.legs = this.legs_results[0];
            this.fs.furniture.plinth.height = (Number(this.fs.furniture.plinth.legs.height) / 10) + this.fs.edgeWidth;
            this.fs.furniture.plinth.legs_id = this.fs.furniture.plinth.legs.id;
        }
        else if (type == 3) {
            this.fs.furniture.plinth.height = this.fs.edgeWidth;
        }
        else if (type == 4) {
            this.fs.furniture.plinth.height = 0;
        }

        for(const column of this.fs.furniture.columns) {
            column.height = this.fs.furniture.height - this.fs.furniture.entresolHeight - this.fs.furniture.plinth.height;

            for(const row of column.rows) {
                row.height = column.height / column.rowsCount;
                row.inner_height = (column.height - (this.fs.edgeWidth * column.rowsCount)) / column.rowsCount;
            }
        }
    }

    setRails(type: number) {
        this.fs.furniture.rails = type;

        if (type == 1) {
            this.fs.furniture.edges = 6;
        }
        else if (type == 2) {
            this.fs.furniture.edges = 8;
        }
        else if (type == 3) {
            this.fs.furniture.edges = 10;
        }
    }
    close() {

        if (this.firstStep) {
            this.firstStep = false;
        }
        else {
            this.$mdDialog.cancel();
        }
        
    }

}