import './Checkout.scss';

import { Component } from '../../components/Component';
import { FiCart, ICartItem } from '../../services/Cart';
import { FiSubHeaderService } from './../../components/FiSubHeader/FiSubHeaderService';
import { FiToastService } from './../../components/fiToast/FiToastService';
import * as angular from 'angular';
import { IUser } from '../../components/fiFurniture/FiFurnitureService';
import { FiPrice } from '../../services/Price';

@Component({
    controllerAs: 'vm',
    template: require('./Checkout.html'),
    bindings: {
    }
})
export class Checkout {
    static $inject = [
        '$window',
        'fiSubHeaderService',
        'fiCart',
        '$http',
        'fiToastService',
        'fiPrice',     
        '$scope',
        '$mdDialog',
        '$timeout',
    ];

    acceptRules: boolean;
    cartStorage: FiCart;
    cartTotalPrice: number = 0;
    order_id: string;
    item_id: string;
    user: IUser;
    logedin: boolean;
    userComments: string;
    userEmailExist: boolean = false;
    assemblyPriceTotal: number = 0;
    carryPriceTotal: number = 0;
    submitForm: boolean = false;
    payseraAmount: string;
    discount: boolean;
    discountCode: string;
    useDiscount: boolean;
    testPayment: boolean;
    freeDelivery: boolean;
    forFree: boolean;
    cartTotalAllItems: number = 0;
    loading: boolean;
    advancePayment: boolean = false;

    constructor(
        private $window: angular.IWindowService,
        private fshs: FiSubHeaderService,
        private cart: FiCart,
        private $http: angular.IHttpService,
        private ts: FiToastService,
        private ps: FiPrice,
        private $scope: angular.IScope,
        private $mdDialog: angular.material.IDialogService,
        private $timeout: angular.ITimeoutService,
    ) {
        /* */
    }

    $onInit() {
        this.fshs.subHeaderIsHidden = true;
        this.cartStorage = JSON.parse(localStorage.getItem('cart'));
        this.cart = this.cartStorage;

        this.user = JSON.parse(localStorage.getItem('currentUser'));
        this.logedin = JSON.parse(localStorage.getItem('currentUser'));

        if (!this.user || this.user.admin) {
            //
        }

        // console.log(this.cart.items);

        for(const item of this.cart.items) {
            this.assemblyPriceTotal = this.assemblyPriceTotal + (item.assemblyPrice * item.count);
            this.carryPriceTotal = this.carryPriceTotal + (item.carryPrice * item.count);
        }

        for(const item of this.cart.items) {
            this.cartTotalAllItems = this.cartTotalAllItems + item.count;
        }

        if (!this.cart.delivery) { this.cart.delivery = false }
        if (!this.cart.assembly) { this.cart.assembly = false }
        if (!this.cart.carry) { this.cart.carry = false }

        // this.cart.assembly = true;

        this.recalculateCart();

        this.$scope.$watch(() => this.discountCode, () => this.useDiscountCode());
        this.$scope.$watch(() => this.advancePayment, () => this.recalculateCart());

    }

    getTotalPriceWithExtra(item: ICartItem) {
        let count = 0;
        count = Number(item.item.price) + Number(item.item.extra_price_1) + Number(item.item.extra_price_2) + Number(item.item.extra_price_3) + Number(item.item.extra_price_4) + Number(item.item.extra_price_5);
        return count;
    }

    getCartTotalPrice() {
        this.cartTotalPrice = 0;
        for(const item of this.cartStorage.items) {
            this.cartTotalPrice = this.cartTotalPrice + (this.getTotalPriceWithExtra(item) * item.count);
        }
        return this.cartTotalPrice;
    }

    getCartPrice() {}

    recalculateCart() {

        this.cart.totalPrice = this.getCartTotalPrice();

        if (this.cart.assembly) {
            this.cart.totalPrice = this.cart.totalPrice + this.assemblyPriceTotal;
        }

        if (this.cart.carry && this.cart.delivery) {
            this.cart.totalPrice = this.cart.totalPrice + this.carryPriceTotal;
        }

        if (!this.cart.delivery) {
            this.cart.carry = false;
        }

        if (this.cart.delivery && !this.freeDelivery) {
            // this.cart.totalPrice = this.cart.totalPrice + 60.50;
            this.cart.totalPrice = this.cart.totalPrice + 45;
        }
        else if (this.cart.delivery && this.freeDelivery) {
            this.cart.totalPrice = this.cart.totalPrice;
        }

        localStorage.setItem('cart', JSON.stringify(this.cart));

         // Paysera
         if (this.advancePayment) {
            this.cart.totalPrice = this.cart.totalPrice / 2;
            this.payseraAmount = (this.cart.totalPrice).toFixed(2).replace(/\./g,'');
         }
         else {
            this.payseraAmount = this.cart.totalPrice.toFixed(2).replace(/\./g,'');
         }

         // console.log(this.payseraAmount);
         
    }

    useDiscountCode() {
        if (this.discount && this.discountCode == 'F2022-A74R!') {
            this.payseraAmount = '1';
            this.useDiscount = true;
            // this.testPayment = true;
        }
        else if (this.discount && this.discountCode == 'fitit02') {
            this.freeDelivery = true;
            this.useDiscount = true;
            this.recalculateCart();
        }
        else if (this.discount && this.discountCode == 'fititfree') {
            this.forFree = true;
            this.useDiscount = true;
        }
        else {
            this.useDiscount = false;
            this.freeDelivery = false;
            this.recalculateCart();
        }
    }

    createOrder() {

        this.order_id = 'FIT' + Math.floor(Math.random() * 899999 + 100000);

        if (this.logedin) {
            this.updateUser();
            this.createOrderLines(this.user.id, this.user.company);
        }
        else {
            this.$http.post("https://www.fitit.lt/db/checkout-check-user.php", { 
                email: this.user.email,

            }, { headers: {'Content-Type': 'application/json'} })
                .then((response: any) => {

                    this.createUser();

                    // if(response.data == 'exist') {
                        // this.ts.show('Vartotojas šiuo el.paštu jau egzistuoja');
                        // this.login();
                        // return;
                    // }
                    // else {
                        // this.createUser();
                    // }

                }
            );
        }

    }

    login() {
        this.$mdDialog.show({
            template: '<fi-login-dialog></fi-login-dialog>',
            parent: angular.element(document.body),
            clickOutsideToClose: true,
            controllerAs: 'vm'
        });
    }

    isOkToGo() {
        if (!this.acceptRules) {
            return true;
        }
        else {
            return false;
        }
    }

    createOrderLines(user_id: string, buiying_c: string) {

        this.loading = true;

        // Creating order
        this.$http.post("https://www.fitit.lt/db/new-order.php", { 

            user_id: user_id,
            price: this.cart.totalPrice,
            order_id: this.order_id,
            user_comments: this.userComments,
            assembly: this.cart.assembly,
            delivery: this.cart.delivery,
            buying_company: buiying_c,
            assembly_price: this.assemblyPriceTotal,
            advance_payment: this.advancePayment,
            carry: this.cart.carry,
            carry_price: this.carryPriceTotal

        }, { headers: {'Content-Type': 'application/json'} })
            .then(function (response) {
                //
        });


        // Adding items
        for(const cart of this.cartStorage.items) {

            this.item_id = 'FIT' + Math.floor(Math.random() * 89999999 + 10000000);

            this.$http.post("https://www.fitit.lt/db/new-order-items.php", { 

                title: cart.item.title,
                price: cart.item.price,
                width: cart.item.width,
                height: cart.item.height,
                order_id: this.order_id,
                item_id: this.item_id,
                count: cart.count,
                depth: cart.item.depth,
                category: 0,
                additional_1: cart.item.additional.option_1,
                additional_2: cart.item.additional.option_2,
                additional_3: cart.item.additional.option_3,
                additional_2_height: cart.item.additional_2_height,
                additional_2_depth: cart.item.additional_2_depth,
                additional_2_unknow: cart.item.additional_2_unknow,
                material_is_mdf: cart.item.material_is_mdf,
                material_has_fillings: cart.item.material_has_fillings,
                material_mdf_id: cart.item.material_mdf_id,
                material_mdf_thickness: cart.item.material_mdf_thickness,
                material_mdf_gloss: cart.item.material_mdf_gloss,
                material_mdf_filling_id: cart.item.material_mdf_filling_id,
                extra_description_1: cart.item.extra_description_1,
                extra_description_2: cart.item.extra_description_2,
                extra_description_3: cart.item.extra_description_3,
                extra_description_4: cart.item.extra_description_4,
                extra_description_5: cart.item.extra_description_5,
                extra_price_1: cart.item.extra_price_1,
                extra_price_2: cart.item.extra_price_2,
                extra_price_3: cart.item.extra_price_3,
                extra_price_4: cart.item.extra_price_4,
                extra_price_5: cart.item.extra_price_5,
                type: cart.item.type,
                quality: cart.item.quality,
                installation: cart.item.installation,
                material_id: cart.item.material.id,
                material_type: cart.item.material_type,
                edges: cart.item.edges,
                columnsCount: cart.item.columnsCount,
                entresol: cart.item.entresol,
                entresolHeight: cart.item.entresolHeight,
                plinth_type: cart.item.plinth.type,
                plinth_height: cart.item.plinth.height,
                plinth_legs_id: cart.item.plinth.legs.id,
                knob_id: cart.item.knob_id,
                rails: cart.item.rails,
                doors_count: cart.item.doors_count,
                with_drawers: cart.item.with_drawers,
                draft: cart.item.draft,
                with_hangers: cart.item.with_hangers,
                orderby: cart.item.orderby,
                price_from: cart.item.price_from,
                material_different_sides: cart.item.material_different_sides,
                slidingDoorsCount: cart.item.slidingDoorsCount,
                material_ls_lmdp_id: cart.item.material_ls_lmdp_id,
                material_rs_lmdp_id: cart.item.material_rs_lmdp_id,
                material_ts_lmdp_id: cart.item.material_ts_lmdp_id,
                material_bs_lmdp_id: cart.item.material_bs_lmdp_id,
                material_ls_type: cart.item.material_ls_type,
                material_rs_type: cart.item.material_rs_type,
                material_ts_type: cart.item.material_ts_type,
                material_bs_type: cart.item.material_bs_type,
                material_ls_is_mdf: cart.item.material_ls_is_mdf,
                material_rs_is_mdf: cart.item.material_rs_is_mdf,
                material_ts_is_mdf: cart.item.material_ts_is_mdf,
                material_bs_is_mdf: cart.item.material_bs_is_mdf,
                material_ls_mdf_id: cart.item.material_ls_mdf_id,
                material_rs_mdf_id: cart.item.material_rs_mdf_id,
                material_ts_mdf_id: cart.item.material_ts_mdf_id,
                material_bs_mdf_id: cart.item.material_bs_mdf_id,
                material_ls_mdf_gloss: cart.item.material_ls_mdf_gloss,
                material_rs_mdf_gloss: cart.item.material_rs_mdf_gloss,
                material_ts_mdf_gloss: cart.item.material_ts_mdf_gloss,
                material_bs_mdf_gloss: cart.item.material_bs_mdf_gloss,
                material_ls_mdf_has_fillings: cart.item.material_ls_mdf_has_fillings,
                material_rs_mdf_has_fillings: cart.item.material_rs_mdf_has_fillings,
                material_ts_mdf_has_fillings: cart.item.material_ts_mdf_has_fillings,
                material_bs_mdf_has_fillings: cart.item.material_bs_mdf_has_fillings,
                material_ls_mdf_filling_id: cart.item.material_ls_mdf_filling_id,
                material_rs_mdf_filling_id: cart.item.material_rs_mdf_filling_id,
                material_ts_mdf_filling_id: cart.item.material_ts_mdf_filling_id,
                material_bs_mdf_filling_id: cart.item.material_bs_mdf_filling_id,
                sliding_door_profiles: cart.item.sliding_door_profiles,
                collect_myself: cart.item.collect_myself


            }, { headers: {'Content-Type': 'application/json'} })
                .then(function (response) {
                    //
            });

            // Adding sliding doors
            for(const door of cart.item.slidingDoors) {
                this.$http.post("https://www.fitit.lt/db/new-furniture-sliding-doors.php", { 
                    item_id: this.item_id,
                    door_id: door.door_id,
                    order_no: door.order_no,
                    divide: door.divide,
                    width: door.width,
                    height: door.height
                }, { headers: {'Content-Type': 'application/json'} })
                    .then(function (response) { 
                        //
                });

                for(const row of door.divideRows) {
                    this.$http.post("https://www.fitit.lt/db/new-furniture-sliding-doors-rows.php", { 
                        door_id: door.door_id,
                        row_id: row.row_id,
                        order_no: row.order_no,
                        width: row.width,
                        height: row.height,
                        item_id: this.item_id,
                        panel_id: row.panel_id,
                        mirror_id: row.mirror_id,
                        knob_id: row.knob_id,
                        panel_type: row.panel_type,
                        mdf_gloss: row.mdf_gloss,
                        mdf_id: row.mdf_id,
                        handle_type: row.handle_type,
                        handle_id: row.handle_id,
                        handle_internal_id: row.handle_internal_id,
                        milled_knob_id: row.milled_knob_id,
                        mdf_thickness: row.mdf_thickness

                    }, { headers: {'Content-Type': 'application/json'} })
                        .then(function (response) { 
                            //
                    });
                }
            }

            // Adding columns
            for(const column of cart.item.columns) {

                this.$http.post("https://www.fitit.lt/db/new-furniture-columns.php", { 
                    item_id: this.item_id,
                    order_no: column.order_no,
                    column_id: column.column_id,
                    rows_count: column.rowsCount,
                    width: column.width,
                    height: column.height,
                    outer_height: column.outer_height,
                    inner_width: column.inner_width

                }, { headers: {'Content-Type': 'application/json'} })
                    .then(function (response) { 
                        //
                });

                for(const row of column.rows) {
                    this.$http.post("https://www.fitit.lt/db/new-furniture-rows.php", { 
                        column_id: column.column_id,
                        row_id: row.row_id,
                        order_no: row.order_no,
                        width: column.width,
                        inner_width: column.inner_width,
                        height: row.height,
                        inner_height: row.inner_height,
                        item_id: this.item_id,
                        panel_id: row.panel_id,
                        handle_id: row.handle_id,
                        milled_knob_id: row.milled_knob_id,
                        handle_internal_id: row.handle_internal_id,
                        handle_type: row.handleType,
                        inner_type: row.inner,
                        outer_type: row.outer,
                        merged: row.merged,
                        parent_row_id: row.parent_row_id,
                        panel_type: row.panelType,
                        is_mdf: row.is_mdf,
                        fillings: row.fillings,
                        mdf_id: row.mdf_id,
                        filling_id: row.filling_id,
                        mdf_thickness: row.mdf_thickness,
                        mdf_gloss: row.mdf_gloss


                    }, { headers: {'Content-Type': 'application/json'} })
                        .then(function (response) { 
                            //
                    });

                        if (row.merged_with) {
                            for(const mrow of row.merged_with) {
                                this.$http.post("https://www.fitit.lt/db/new-furniture-merged-row.php", { 
                                    item_id: this.item_id,
                                    row_id: mrow.row_id,
                                    parent_row_id: mrow.parent_row_id,
                                    row_height: mrow.row_height,
                                    row_width: mrow.row_width,
                                    order_no: mrow.order_no,

                                }, { headers: {'Content-Type': 'application/json'} })
                                    .then(function (response) { 
                                        //
                                });
                            }
                        }
                        
                }

            }

            this.item_id = '';
        }

        // Paysera
        if (this.discount && this.discountCode == 'F2022-A74R!') {
            this.payseraAmount = '1';
        }
        else {
            this.payseraAmount = this.cart.totalPrice.toFixed(2).replace(/\./g,'');
        }

        window['dataLayer'] = window['dataLayer'] || [];
        window['dataLayer'].push({ ecommerce: null });  // Clear the previous ecommerce object.
        window['dataLayer'].push({
            'event': 'checkout',
            'ecommerce': {
                'checkout': {
                    'actionField': {'step': 2 },
                    'user_data': {
                        'email_address': this.user.email,
                        'phone_number': this.user.phone,
                        'address': {
                            'first_name': this.user.name,
                            'last_name': this.user.surname,
                            'city': this.user.city,
                            'country': 'lt'     
                        }
                    },
                    'products': [{
                        'name': this.cartStorage.items[0].item.title,
                        'id': this.cartStorage.items[0].item.item_id,
                        'price': this.cartStorage.items[0].item.price / 100,
                        'quantity': 1
                    }]
                }
            }
        });

        const orderInfo = {
            name: this.user.name,
            email: this.user.email,
            order_id: this.order_id
        }

        localStorage.setItem('orderInfo', JSON.stringify(orderInfo));
        
        if (this.forFree) {
            this.$timeout(() => {
                this.$window.location.href = '/thank-you';
                this.loading = false;
            }, 3000);
            
        }
        else {

            const checkoutForm = <HTMLFormElement>document.getElementById("checkoutForm");
            checkoutForm.submit();
            this.loading = false;

        }
        
    }

    createUser() {

        this.$http.post("https://www.fitit.lt/db/checkout-new-user.php", { 

            name: this.user.name,
            surname: this.user.surname,
            email: this.user.email,
            phone: this.user.phone,
            city: this.user.city,
            address: this.user.address,
            zip: this.user.zip,
            company: this.user.company,
            company_name: this.user.company_name,
            company_code: this.user.company_code,
            company_vat: this.user.company_vat,
            company_address: this.user.company_address,
            advertising: this.user.advertising,
            password: 'FIT' + Math.floor(Math.random() * 899999 + 100000),


        }, { headers: {'Content-Type': 'application/json'} })
            .then((response: any) => {

                const currentUser = {
                    id: response.data[0].id,
                    name: this.user.name,
                    surname: this.user.surname,
                    email: this.user.email,
                    phone: this.user.phone,
                    city: this.user.city,
                    address: this.user.address,
                    zip: this.user.zip,
                    company: this.user.company,
                    company_name: this.user.company_name,
                    company_code: this.user.company_code,
                    company_vat: this.user.company_vat,
                    company_address: this.user.company_address,
                    advertising: this.user.advertising,
                    created: this.user.created,
                    last_login: this.user.last_login,
                    favorites: this.user.favorites,
                    orders: this.user.orders,
                    admin: this.user.admin
                }

                // localStorage.setItem('currentUser', JSON.stringify(currentUser));
                // this.user = JSON.parse(localStorage.getItem('currentUser'));

                this.createOrderLines(response.data[0].id, response.data[0].company);

            }
        );
    }

    updateUser() {

        this.$http.post("https://www.fitit.lt/db/user-profile.php", { 

            id: this.user.id,
            name: this.user.name,
            surname: this.user.surname,
            email: this.user.email,
            phone: this.user.phone,
            city: this.user.city,
            address: this.user.address,
            zip: this.user.zip,
            company: this.user.company,
            company_name: this.user.company_name,
            company_code: this.user.company_code,
            company_vat: this.user.company_vat,
            company_address: this.user.company_address,
            advertising: this.user.advertising

        }, { headers: {'Content-Type': 'application/json'} })
            .then((response: any) => {

                if(response.data == 'return') {
                    return
                }
                else {
                    
                    const currentUser = {
                        id: this.user.id,
                        name: this.user.name,
                        surname: this.user.surname,
                        email: this.user.email,
                        phone: this.user.phone,
                        city: this.user.city,
                        address: this.user.address,
                        zip: this.user.zip,
                        company: this.user.company,
                        company_name: this.user.company_name,
                        company_code: this.user.company_code,
                        company_vat: this.user.company_vat,
                        company_address: this.user.company_address,
                        advertising: this.user.advertising,
                        created: this.user.created,
                        last_login: this.user.last_login,
                        favorites: this.user.favorites,
                        orders: this.user.orders,
                        admin: this.user.admin
                    }

                    localStorage.setItem('currentUser', JSON.stringify(currentUser));

                }
            }
        );

    }
}
