import './ItemDetails.scss';
import { FiSubHeaderService } from './../../components/FiSubHeader/FiSubHeaderService';

//import { IFurnitureItem, FiFurnitureService } from './../../components/fiFurniture/FiFurnitureService';
import { FiFurnitureService, IFurnitureItem, IRowItem, IColumnItem } from '../../components/fiFurniture/FiFurnitureService';

import { Component } from '../../components/Component';
import { FiCart } from '../../services/Cart';
import { FiPrice } from '../../services/Price';
// import * as uirouter from '@uirouter/angularjs';
import * as uirouter from 'angular-ui-router';
import * as angular from 'angular';

import lightGallery from "lightgallery";
import lgThumbnail from "lightgallery/plugins/thumbnail";

@Component({
    controllerAs: 'vm',
    template: require('./ItemDetails.html'),
    bindings: {
    }
})
export class ItemDetails {
    static $inject = [
        '$http',
        '$location',
        'fiFurnitureService',
        'fiCart',
        '$window',
        '$mdDialog',
        'fiPrice',
        '$state',
        '$timeout',
        'fiSubHeaderService',
    ];


    constructor(
        private $http: angular.IHttpService,
        private $location: angular.ILocationService,
        private fs: FiFurnitureService,
        private cart: FiCart,
        private $window: angular.IWindowService,
        private $mdDialog: angular.material.IDialogService,
        private ps: FiPrice,
        private $state: uirouter.StateService,
        private $timeout: angular.ITimeoutService,
        private fshs: FiSubHeaderService,
    ) {
        /* */
    }

    counter: number = 0;

    data = [];
    itemCartCount: number = 1;
    user: any;
    cartStorage: FiCart;
    mainImage: number = 3;
    userFavorites = [];
    item_id: string;
    itemIsFavorite: boolean = false;
    activeTab: number = 3;

    title: string;
    text: string;
    useAssemblyPrice: boolean;
    useCarryPrice: boolean;
    useDelivery: boolean;
    print: string;

    gallery = [];

    $onInit() {
        // this.item_id = this.$location.search().id;

        this.item_id = this.$state.params['itemId'];
        // this.fs.newItem = false;
        this.fs.selectFurniture(this.item_id, false);

        this.print = this.$location.search().print;

        if (this.print == '1') {
            this.fshs.subHeaderIsHidden = true;
            this.fshs.blankPage = true;
        }
        

        this.user = JSON.parse(localStorage.getItem('currentUser'));
        if (this.user) {
            this.getUserFavorites();
        }

        this.$http.get('https://www.fitit.lt/db/admin-gallery.php').then((data) => {
            this.gallery.push(data);
        });

        this.$timeout(() => {
            window['dataLayer'] = window['dataLayer'] || [];
            window['dataLayer'].push({ ecommerce: null });  // Clear the previous ecommerce object.
            window['dataLayer'].push({
            'event': 'ProductView',
            'ecommerce': {
                'detail': {
                'products': [{
                    'name': this.fs.furniture.title,         // Name or ID is required.
                    'id': this.fs.furniture.item_id,
                    'price': this.fs.furniture.price
                    // 'category': 'Apparel',
                    // 'variant': 'Gray'
                }]
                }
            }
            });

        }, 2000);

        this.$timeout(() => {
            if (this.fs.furniture.type == 2) {
                this.activeTab = 4;
            }
        }, 2000);


        this.$timeout(() => {
            this.constructGallery();
        }, 1000);
        
    }

    constructGallery() {
        const $galleryContainer = document.getElementById("gallery-container");

        const lg = lightGallery($galleryContainer, {
            speed: 500,
            plugins: [lgThumbnail],
            enableDrag: false,
            selector: '.item-details-gallery-item',
            enableSwipe: true,
            download : false,
            controls: true,
            closeOnTap  : false,
            mobileSettings: {
                showCloseIcon: true,
                controls: true,
            }
        });

        lg.outer.on("click", (e) => {
        const $item = lg.outer.find(".lg-current .lg-image");
        if (
            e.target.classList.contains("lg-image") ||
            $item.get().contains(e.target)
        ) {
            lg.goToNextSlide();
        }
        });
    }

    getImageUrl(url: string) {
        return 'https://www.fitit.lt/gallery/' + url;
    }
    
    getTotalPriceWithExtra() {
        let count = 0;
        count = Number(this.fs.furniture.price) + Number(this.fs.furniture.extra_price_1) + Number(this.fs.furniture.extra_price_2) + Number(this.fs.furniture.extra_price_3) + Number(this.fs.furniture.extra_price_4) + Number(this.fs.furniture.extra_price_5);
        return count;
    }

    getPrice() {
        return this.getTotalPriceWithExtra() + (this.useAssemblyPrice ? this.ps.getAssemblyPrice() : 0) + (this.useCarryPrice ? this.ps.getCarryPrice() : 0) + (this.useDelivery ? 45.00 : 0);
    }

    modifyCart(itemCount: number) {
        if (itemCount < 1 || itemCount > 99) {
            return;
        }

        this.itemCartCount = itemCount;
    }

    viewImage(img) {
        this.$window.open(img, '_blank');
    }

    viewSelectedMirror(item) {
        this.fs.mirrorItem = item;
        this.$mdDialog.show({
            template: '<fi-mirror-dialog></fi-mirror-dialog>',      
            parent: angular.element(document.body),
            clickOutsideToClose: true,
            controllerAs: 'vm'
        });
    }

    getInstallationPic() {
        if (this.fs.furniture.installation == 1) { return 'imontavimas_1.jpg'; }
        else if (this.fs.furniture.installation == 2) { return 'imontavimas_2.jpg'; }
        else if (this.fs.furniture.installation == 3) { return 'imontavimas_3.jpg'; }
        else if (this.fs.furniture.installation == 4) { return 'imontavimas_4.jpg'; }
        else if (this.fs.furniture.installation == 5) { return 'imontavimas_5.jpg'; }
        else if (this.fs.furniture.installation == 6) { return 'imontavimas_6.jpg'; }
        else if (this.fs.furniture.installation == 7) { return 'imontavimas_7.jpg'; }
        else if (this.fs.furniture.installation == 8) { return 'imontavimas_5.jpg'; }
        else if (this.fs.furniture.installation == 9) { return 'imontavimas_6.jpg'; }
        else if (this.fs.furniture.installation == 10) { return 'imontavimas_2.jpg'; }
        else if (this.fs.furniture.installation == 11) { return 'imontavimas_3.jpg'; }
        else if (this.fs.furniture.installation == 12) { return 'imontavimas_4.jpg'; }
        else if (this.fs.furniture.installation == 13) { return 'imontavimas_5.jpg'; }
        else if (this.fs.furniture.installation == 14) { return 'imontavimas_6.jpg'; }
        else if (this.fs.furniture.installation == 15) { return 'imontavimas_7.jpg'; }
        else if (this.fs.furniture.installation == 16) { return 'imontavimas_6.jpg'; }
    }

    getInstallationInfoTitle() {
        if (this.fs.furniture.installationType == 1) { return 'Įmontavimas'; }
        else if (this.fs.furniture.installationType == 2) { return 'Laisvai pastatoma nišoje'; }
        else if (this.fs.furniture.installationType == 3) { return 'Įmontuojama'; }
        else if (this.fs.furniture.installationType == 4) { return 'Pakabinama'; }
    }

    getInstallationInfo() {
        if (this.fs.furniture.installation == 1) { return 'Laisvai pastatoma'; }
        else if (this.fs.furniture.installation == 2) { return 'Tvirtinama tik prie lubų'; }
        else if (this.fs.furniture.installation == 3) { return 'Tvirtinama prie lubų ir k. sienos'; }
        else if (this.fs.furniture.installation == 4) { return 'Tvirtinama prie lubų ir d. sienos'; }
        else if (this.fs.furniture.installation == 5) { return 'Tvirtinama prie lubų ir abiejų sienų'; }
        else if (this.fs.furniture.installation == 6) { return 'Tvirtinama prie abiejų sienų'; }
        else if (this.fs.furniture.installation == 7) { return 'Nepasirinkta'; }
        else if (this.fs.furniture.installation == 8) { return 'Nepasirinkta'; }
        else if (this.fs.furniture.installation == 9) { return 'Nepasirinkta'; }
        else if (this.fs.furniture.installation == 10) { return 'Iki lubų'; }
        else if (this.fs.furniture.installation == 11) { return 'Iki lubų ir k. sienos'; }
        else if (this.fs.furniture.installation == 12) { return 'Iki lubų ir d. sienos'; }
        else if (this.fs.furniture.installation == 13) { return 'Iki lubų ir abiejų sienų'; }
        else if (this.fs.furniture.installation == 14) { return 'Iki abiejų sienų'; }
        else if (this.fs.furniture.installation == 15) { return 'Tik pakabinama'; }
        else if (this.fs.furniture.installation == 16) { return 'iki abiejų sienų'; }
    }

    showTextDialog(type) {


        if (type == 1) {
            this.title = 'Furnitūra (bėgeliai ir lankstai)';
            this.text = '<p><strong>Ekonominė.</strong> Šis pasirinkimas tinkamas kuriantiems pigesnį baldą. Ekonominės klasės baldų furnitūra bus kokybiška ir ilgaamžė. Tačiau ji neturės tų savybių, kurias turi naujausi lyderiaujančių gamintojų gaminiai.</p><p>Baldų furnitūra bus be tylaus uždarymo funkcijos. Jūsų baldu labiausiai tinkančią furnitūrą parinksime iš tokių gamintojų kaip: FGV (Italija), GTV (Lenkija) ar pan.</p><p>Stalčių bėgeliai – balde bus naudojami ritininiai (su ratukais) arba guoliniai stalčių bėgeliai. Abiejų rūšių bėgeliai montuojami stalčiaus šone.</p><p><strong>Premium.</strong> Šis pasirinkimas tinkamas ieškantiems optimalaus komforto ir kainos santykio. Baldo furnitūra bus ilgaamžė ir atitiks visus Jūsų šiandieninius poreikius. Šios klasės furnitūra apima lyderiaujančių gamintojų produkciją.</p><p>Baldų furnitūra bus su tylaus uždarymo funkcija. Jūsų baldu labiausiai tinkančią furnitūrą parinksime iš tokių gamintojų kaip: FGV (Italija), GTV (Lenkija) ar pan.</p><p>Stalčių bėgeliai – balde bus naudojami guoliniai arba paslėptieji stalčių bėgeliai. Guoliniai stalčių bėgeliai montuojami stalčiaus šone, o paslėptieji stalčiaus apačioje.</p><p><strong>Premium+.</strong> Šis pasirinkimas tinkamas kuriantiems aukščiausio komforto baldą. Baldo furnitūra bus ilgaamžė ir viršys Jūsų šiandieninius poreikius. Šios klasės furnitūra apima pažangiausią baldų furnitūrą iš inovatyviausių ir stipriausių gamintojų.</p><p>Baldų furnitūra bus su tylaus uždarymo funkcija. Jūsų baldu labiausiai tinkančią furnitūrą parinksime iš tokių gamintojų kaip: Grass (Austrija), Blum (Austrija).</p><p>Stalčių bėgeliai – balde bus naudojami guoliniai arba paslėptieji stalčių bėgeliai. Guoliniai stalčių bėgeliai montuojami stalčiaus šone, o paslėptieji stalčiaus apačioje.</p>'
        }
        else if (type == 2) {
            this.title = 'Briaunos';
            this.text = '<p><strong>Durelės dengia briaunas.</strong> Durelės uždengs priekines baldo briaunas, todėl briaunos nebus matomos. Šis pasirinkimas suteiks Jūsų baldui „lengvumo“.</p><p><strong>Matosi visos.</strong> Durelės bus įleistos į vidų, todėl visos baldo briaunos bus matomos. Šis pasirinkimas suteiks Jūsų baldui „solidumo“.</p><p><strong>Matosi viršus ir apačia.</strong> Durelės uždengs šonines ir vidines briaunas, tačiau viršutinės ir apatinės briaunos bus matomos. Šis pasirinkimas labiau tinkamas baldams su kojelėmis.</p><p><strong>Matosi viršus.</strong> Durelės uždengs apatinę, šonines ir vidines briaunas, tačiau viršutinė briauna bus matoma. Baldas su stogeliu yra tinkamas pasirinkimas baldams su padu.</p><p><strong>Matosi išorinės.</strong> Durelės uždengs vidines briaunas, tačiau viršutinė, apatinė ir šoninės briaunos bus matomos. Šis pasirinkimas įrėmins Jūsų susikurtą baldą.</p>'
        }
        else if (type == 3) {
            this.title = 'Pagrindas';
            // this.text = '<p>Rinkitės pagrindą su „Padu“ arba „Kojelėmis“, jei apatinis skyrius bus su varstomomis durimis arba stalčiais.</p><p>„Plokštė“ ir „Be pagrindo“ tinkamas pasirinkimas spintoms su slankiojančiomis durimis, atviroms lentynoms ir pan.</p><p>Pagrindo aukštis įeina į bendrą baldo aukštį, nurodytą ankstesniame žingsnyje.</p>'
            this.text = '<p>Rinkitės pagrindą su „Padu“, „Cokoliu“ arba „Kojelėmis“, jei apatinis skyrius bus su varstomomis durimis arba stalčiais.</p><p>„Plokštė“ ir „Be pagrindo“ tinkamas pasirinkimas spintoms su slankiojančiomis durimis, atviroms lentynoms ir pan.</p><p>Baldo su „Padu“ šonai yra iki pat grindų. Puikiai tinkantis pasirinkimas, kai yra matomas šonas.</p><p>Baldo su „Cokoliu“ arba „Kojelėmis“ šonai nesiekia grindų, todėl tinka baldams nišose, kur baldas turi būti iškeltas virš grindjuosčių.</p><p>Pagrindo aukštis įeina į bendrą baldo aukštį, nurodytą ankstesniame žingsnyje.</p>';
        }
        else if (type == 4) {
            this.title = 'Papildomi pasirinkimai';
            this.text = '<p><strong>Galinė sienelė</strong> yra naudojame daugumoje baldų. Nepaisant to, kad ji yra itin plona, ji suteikia baldui papildomo stabilumo. Todėl rekomenduojame galinę sienelę pasirinkti. Galinės sienelės galite nesirinkti tam tikrais atvejais. Pavyzdžiui, jei Jūsų baldas bus pritvirtintas prie sienos.</p><p><strong>Grindjuostės išpjovimas</strong> yra reikalingas tam, kad neliktų tarpo tarp baldo ir galinės sienos už jo. Baldo gale apačioje išpjaunama stačiakampio forma apgaubianti grindjuostę. Taip baldas priglunda prie sienos.</p><p>Papildomos <strong>Lentynų skylutės</strong> suteikia galimybę keisti lentynų aukštį patiems. Mes išgręšime papildomų skylučių spintos vidinėje dalyje, kad jūs lengvai galėtumėte keisti lentynų aukštį.</p>'
        }
        else if (type == 5) {
            this.title = 'Įmontavimas';
            this.text = '<p><strong>Laisvai pastatomą</strong> baldą galima perkelti iš vienos vietos į kitą. Tai ypač patogu, kai nesate tikri dėl baldo stovėjimo vietos.</p><p><strong>Laisvai pastatomi baldai nišoje</strong> yra gamykliškai pagaminti baldai su pilnais šonais ir galine sienele. Tarpai tarp spintos šonų ir sienų gali būti 5-7 mm. Tokie baldai puikiai tinka tuose vietose, kur sienos/lubos/grindys neturi kreivumo. <u>Atvyksime į vietą patikslinti matmenis.</u></p><p><strong>Įmontuojami</strong> baldai yra tvirtinami prie sienų, lubų ar grindų. Tokie baldai yra „gaminami“ vietoje, nes kiekviena detalė yra pjaunama ir pritaikoma pagal konkrečius matmenis. Įmontuojami baldai paprastai neturi pilnų šoninių sienų (lentynos tvirtinamos tiesiai į sieną) ir galinės nugarėlės. <u>Atvyksime į vietą patikslinti matmenis.</u></p><p><strong>Pakabinami</strong> baldai tokie baldai, kurių galinė dalis tvirtinama prie sienos. <u>Atvyksime į vietą patikslinti matmenis</u>, jei baldas bus pakabinamas nišoje.</p>'
        }
        

        this.$mdDialog.show({
            template: '<fi-text-modal title="' + this.title + '" text="' + this.text + '"></fi-text-modal>',
            parent: angular.element(document.body),
            clickOutsideToClose: true,
            controllerAs: 'vm'
        });
    }

    countDoors() {
        this.counter = 0;

        this.fs.furniture.columns.forEach((column: IColumnItem) => {
            column.rows.forEach((row: IRowItem) => {
                if (row.outer == 1 || row.outer == 2 || row.outer == 3 || row.outer == 4 || row.outer == 5) {
                    this.counter = this.counter + 1;
                }
            });
        });

        return this.counter;
    }

    showDialog(item, category, mdf_gloss) {

        if (category == 1) {

            this.fs.panelItem = item;
            this.$mdDialog.show({
                template: '<fi-material-dialog type="1" hide-button="true"></fi-material-dialog>',
                parent: angular.element(document.body),
                clickOutsideToClose: true,
                controllerAs: 'vm'
            });
            
        }
        else if (category == 2) {
            this.fs.legItem = item;
            this.$mdDialog.show({
                template: '<fi-fe-leg-dialog hide-button="true"></fi-fe-leg-dialog>',
                parent: angular.element(document.body),
                clickOutsideToClose: true,
                controllerAs: 'vm'
            });

        }
        else if (category == 3) {
            this.fs.handleItem = item;
            this.$mdDialog.show({
                template: '<fi-fe-handle-dialog hide-button="true"></fi-fe-handle-dialog>',
                parent: angular.element(document.body),
                clickOutsideToClose: true,
                controllerAs: 'vm'
            });
        }
        else if (category == 4) {
            this.fs.handleInternalItem = item;
            this.$mdDialog.show({
                template: '<fi-fe-handle-internal-dialog hide-button="true"></fi-fe-handle-internal-dialog>',
                parent: angular.element(document.body),
                clickOutsideToClose: true,
                controllerAs: 'vm'
            });
        }
        else if (category == 5) {
            this.fs.mdfItem = item;
            this.fs.selected_mdf_gloss = mdf_gloss;
          
            this.$mdDialog.show({
                template: '<fi-material-dialog type="4" hide-button="true"></fi-material-dialog>',
                parent: angular.element(document.body),
                clickOutsideToClose: true,
                controllerAs: 'vm'
            });
        }
    }

    showChoosePlates() {
        this.$mdDialog.show({
            template: '<fi-modal-top-five></fi-reload-five>',
            parent: angular.element(document.body),
            clickOutsideToClose: true,
            controllerAs: 'vm'
        });
    }

    goToConfiguratorLink() {
        this.$window.location.href = '/konfiguratorius/';
    }
    
    showImage() {
        this.$mdDialog.show({
            contentElement: '#imageDialog',
            parent: angular.element(document.body),
            clickOutsideToClose: true
        });
    }

    close() {
        this.$mdDialog.cancel();
    }

    viewSelectedPanelFilling(item) {
        this.fs.fillingItem = item;

        this.$mdDialog.show({
            template: '<fi-panel-filling-dialog></fi-panel-filling-dialog>',
            parent: angular.element(document.body),
            clickOutsideToClose: true,
            controllerAs: 'vm'
        });
    }

    viewKnobProfile(item) {
        this.fs.knobItem = item;
        this.$mdDialog.show({
            template: '<fi-knob-dialog></fi-knob-dialog>',
            parent: angular.element(document.body),
            clickOutsideToClose: true,
            controllerAs: 'vm'
        });
    }

    getInfo(type: string) {
        if (type == 'type') {
            if (this.fs.furniture.type == 1) { return 'Spinta su varstomomis durimis'; }
            else if (this.fs.furniture.type == 2) { return 'Spinta su stumdomis durimis'; }
            else if (this.fs.furniture.type == 3) { return 'Komoda'; }
            else if (this.fs.furniture.type == 4) { return 'Lentyna'; }
            else if (this.fs.furniture.type == 5) { return 'TV spintelė'; }
            else if (this.fs.furniture.type == 6) { return 'Spintelė'; }
        }
        else if (type == 'installation') {
            if (this.fs.furniture.installation == 1) { return 'Laisvai pastatoma'; }
            else if (this.fs.furniture.installation == 2) { return 'Tvirtinama tik prie lubų'; }
            else if (this.fs.furniture.installation == 3) { return 'Tvirtinama prie lubų ir k. sienos'; }
            else if (this.fs.furniture.installation == 4) { return 'Tvirtinama prie lubų ir d. sienos'; }
            else if (this.fs.furniture.installation == 5) { return 'Tvirtinama prie lubų ir abiejų sienų'; }
            else if (this.fs.furniture.installation == 6) { return 'Tvirtinama prie abiejų sienų'; }
            else if (this.fs.furniture.installation == 7) { return 'Pakabinama'; }
        }
        else if (type == 'plinth') {
            if (this.fs.furniture.plinth.type == 1) { return 'Padas'; }
            else if (this.fs.furniture.plinth.type == 2) { return 'Kojelės'; }
            else if (this.fs.furniture.plinth.type == 3) { return 'Plokštė'; }
            else if (this.fs.furniture.plinth.type == 4) { return 'Be pagrindo'; }
            else if (this.fs.furniture.plinth.type == 5) { return 'Cokolis'; }
        }
        else if (type == 'quality') {
            if (this.fs.furniture.quality == 1) { return 'Ekonominė'; }
            else if (this.fs.furniture.quality == 2) { return 'Premium'; }
            else if (this.fs.furniture.quality == 3) { return 'Premium +'; }
        }
        else if (type == 'edges') {
            if (this.fs.furniture.edges == 1) { return 'Durelės dengia briaunas'; }
            else if (this.fs.furniture.edges == 2) { return 'Matosi visos'; }
            else if (this.fs.furniture.edges == 3) { return 'Matosi viršus ir apačia'; }
            else if (this.fs.furniture.edges == 4) { return 'Matosi viršus'; }
            else if (this.fs.furniture.edges == 5) { return 'Matosi išorinės'; }
            else if (this.fs.furniture.edges == 6) { return 'Be stogelio (matosi šonai)'; }
            else if (this.fs.furniture.edges == 7) { return 'Su stogeliu (matosi visos)'; }
            else if (this.fs.furniture.edges == 8) { return 'Durelės dengia briaunas'; }
            else if (this.fs.furniture.edges == 9) { return 'Matosi šonai'; }
            else if (this.fs.furniture.edges == 10) { return 'Matosi viršus ir šonai'; }
            else if (this.fs.furniture.edges == 11) { return 'Matosi išorinės ir vertikalios'; }
            else if (this.fs.furniture.edges == 12) { return 'Matosi išorinės ir horizontalius'; }
            else if (this.fs.furniture.edges == 13) { return 'Matosi šonai'; }
        }
    }
    getInfoPic(type: string) {
        if (type == 'type') {
            if (this.fs.furniture.type == 1) { return '/img/icons/baldotipas/baldo_tipas_1.jpg'; }
            else if (this.fs.furniture.type == 2) { return '/img/icons/baldotipas/baldo_tipas_2.svg'; }
            else if (this.fs.furniture.type == 3) { return '/img/icons/baldotipas/baldo_tipas_3.jpg'; }
            else if (this.fs.furniture.type == 4) { return '/img/icons/baldotipas/baldo_tipas_4.jpg'; }
            else if (this.fs.furniture.type == 5) { return '/img/icons/baldotipas/baldo_tipas_5.jpg'; }
            else if (this.fs.furniture.type == 6) { return '/img/icons/baldotipas/baldo_tipas_6.jpg'; }
        }
        else if (type == 'installation') {
            if (this.fs.furniture.installation == 1) { return '/img/icons/imontavimas/imontavimas_1_active.jpg'; }
            else if (this.fs.furniture.installation == 2) { return '/img/icons/imontavimas/imontavimas_2_active.jpg'; }
            else if (this.fs.furniture.installation == 3) { return '/img/icons/imontavimas/imontavimas_3_active.jpg'; }
            else if (this.fs.furniture.installation == 4) { return '/img/icons/imontavimas/imontavimas_4_active.jpg'; }
            else if (this.fs.furniture.installation == 5) { return '/img/icons/imontavimas/imontavimas_5_active.jpg'; }
            else if (this.fs.furniture.installation == 6) { return '/img/icons/imontavimas/imontavimas_6_active.jpg'; }
            else if (this.fs.furniture.installation == 7) { return '/img/icons/imontavimas/imontavimas_7_active.jpg'; }
        }
        else if (type == 'plinth') {
            if (this.fs.furniture.plinth.type == 1) { return '/img/icons/pagrindas/pagrindas_1_active.jpg'; }
            else if (this.fs.furniture.plinth.type == 2) { return '/img/icons/pagrindas/pagrindas_2_active.jpg'; }
            else if (this.fs.furniture.plinth.type == 3) { return '/img/icons/pagrindas/pagrindas_3_active.jpg'; }
            else if (this.fs.furniture.plinth.type == 4) { return '/img/icons/pagrindas/pagrindas_4_active.jpg'; }
            else if (this.fs.furniture.plinth.type == 5) { return '/img/icons/pagrindas/pagrindas_5_active.jpg'; }
        }
        else if (type == 'quality') {
            if (this.fs.furniture.quality == 1) { return '/img/icons/papildomos_paslaugos/furnituros_klase_1.jpeg'; }
            else if (this.fs.furniture.quality == 2) { return '/img/icons/papildomos_paslaugos/furnituros_klase_2.jpeg'; }
            else if (this.fs.furniture.quality == 3) { return '/img/icons/papildomos_paslaugos/furnituros_klase_3.jpeg'; }
        }
        else if (type == 'edges') {
            if (this.fs.furniture.edges == 1) { return '/img/icons/briaunos/briaunos_1.jpg'; }
            else if (this.fs.furniture.edges == 2) { return '/img/icons/briaunos/briaunos_2.jpg'; }
            else if (this.fs.furniture.edges == 3) { return '/img/icons/briaunos/briaunos_3.jpg'; }
            else if (this.fs.furniture.edges == 4) { return '/img/icons/briaunos/briaunos_4.jpg'; }
            else if (this.fs.furniture.edges == 5) { return '/img/icons/briaunos/briaunos_5.jpg'; }
            else if (this.fs.furniture.edges == 6) { return '/img/icons/briaunos/briaunos_6.jpg'; }
            else if (this.fs.furniture.edges == 7) { return '/img/icons/briaunos/briaunos_7.jpg'; }
            else if (this.fs.furniture.edges == 8) { return '/img/icons/briaunos/briaunos_8.jpg'; }
            else if (this.fs.furniture.edges == 9) { return '/img/icons/briaunos/briaunos_9.jpg'; }
            else if (this.fs.furniture.edges == 10) { return '/img/icons/briaunos/briaunos_10.jpg'; }
            else if (this.fs.furniture.edges == 11) { return '/img/icons/briaunos/briaunos_11.jpg'; }
            else if (this.fs.furniture.edges == 12) { return '/img/icons/briaunos/briaunos_12.jpg'; }
            else if (this.fs.furniture.edges == 13) { return '/img/icons/briaunos/briaunos_13.jpg'; }
        }
    }

    getOuterName(type: number) {
        if (type == 0) {
            return 'Be durelių';
        }
        else if (type == 1) {
            return 'Durys (Kairė)';
        }
        else if (type == 2) {
            return 'Durys (Dešinė)';
        }
        else if (type == 3) {
            return 'Durys (Dvigubos)';
        }
        else if (type == 4) {
            return 'Durys (Viršus)';
        }
        else if (type == 5) {
            return 'Durys (Apačia)';
        }
        else if (type == 6) {
            return 'Stalčius';
        }
    }

    switchImage(imageNumber) {

        if (imageNumber < 1 || imageNumber > 6) {
            return
        }

        this.mainImage = imageNumber;
    }

    addToFavorites() {

        if (this.itemIsFavorite) {
            this.$http.post("https://www.fitit.lt/db/remove-user-favorites.php", { 

                itemID: this.item_id,
                userID: this.user.id,
    
            }, { headers: {'Content-Type': 'application/json'} })
                .then((response: any) => {
    
                    console.log(response);
                    this.itemIsFavorite = false;
    
            });
        }
        else {
            this.$http.post("https://www.fitit.lt/db/add-to-favorites.php", { 

                itemID: this.item_id,
                userID: this.user.id,
    
            }, { headers: {'Content-Type': 'application/json'} })
                .then((response: any) => {
                    this.itemIsFavorite = true;
            });
        }

        this.getUserFavorites();
    }

    orderCarpenter() {
        this.$mdDialog.show({
            template: '<fi-modal-top-six></fi-modal-top-six>',
            parent: angular.element(document.body),
            clickOutsideToClose: true,
            controllerAs: 'vm'
        });
    }

    getUserFavorites() {
        this.$http.post("https://www.fitit.lt/db/get-user-favorites.php", { 

            userID: this.user.id,

        }, { headers: {'Content-Type': 'application/json'} })
            .then((response: any) => {
                // console.log(response);
                const itemExist = response.data.filter((x) => x.item_id === this.item_id);
                if (itemExist.length == 0) {
                    this.itemIsFavorite = false;
                }
                else {
                    this.itemIsFavorite = true;
                }
        });
    }

    getCount(type: string) {

        this.counter = 0;

        this.fs.furniture.columns.forEach((column: IColumnItem) => {
            column.rows.forEach((row: IRowItem) => {


                if (type == 'inner_drawers') {
                    if (row.inner == 4 || row.inner == 5 || row.inner == 6 ) {
                        this.counter = this.counter + 1;
                    }
                }
                else if (type == 'inner_drawers_double') {
                    if (row.inner == 7) {
                        this.counter = this.counter + 1;
                    }
                }
                else if (type == 'clothes') {
                    if (row.inner == 1) {
                        this.counter = this.counter + 1;
                    }
                }
                else if (type == 'clothes_double') {
                    if (row.inner == 2) {
                        this.counter = this.counter + 1;
                    }
                }
                else if (type == 'separations') {
                    if (row.inner == 3) {
                        this.counter = this.counter + 1;
                    }
                }
                else if (type == 'doors') {
                    if (row.outer == 1 || row.outer == 2 || row.outer == 4 || row.outer == 5) {
                        this.counter = this.counter + 1;
                    }
                }
                else if (type == 'doors_double') {
                    if (row.outer == 3) {
                        this.counter = this.counter + 1;
                    }
                }
                else if (type == 'outer_drawers') {
                    if (row.outer == 6) {
                        this.counter = this.counter + 1;
                    }
                }


                
            });
        });

        return this.counter;
    }
    

    goToConfigurator(item_id: string) {

        if (!this.user || !this.user.admin) {
            this.$window.location.href = '/konfiguratorius/' + item_id;
        }
        else {
            this.$window.location.href = '/konfiguratorius/' + item_id + '?edit=1';
        }
        
    }

    addToCart() {
        this.cartStorage = JSON.parse(localStorage.getItem('cart'));
        if (this.cartStorage) {
            this.cart.items = this.cartStorage.items;
        }
        this.cart.items.push({
            item: this.fs.furniture,
            count: this.itemCartCount,
            assemblyPrice: this.ps.getAssemblyPrice(),
            carryPrice: this.ps.getCarryPrice()
        });

        this.cart.count = this.cart.items.length;
        this.cart.delivery = this.useDelivery;
        this.cart.assembly = this.useAssemblyPrice;
        this.cart.environment = this.fs.environment;
        this.cart.carry = this.useCarryPrice;

        localStorage.setItem('cart', JSON.stringify(this.cart));

        //
        window['dataLayer'] = window['dataLayer'] || [];
        window['dataLayer'].push({ ecommerce: null });  // Clear the previous ecommerce object.
        window['dataLayer'].push({
        'event': 'addToCart',
        'ecommerce': {
            'currencyCode': 'EUR',
            'add': {                                // 'add' actionFieldObject measures.
            'products': [{                        //  adding a product to a shopping cart.
                'name': this.fs.furniture.title,
                'id': this.fs.furniture.item_id,
                'price': this.fs.furniture.price,
                'quantity': 1
            }]
            }
        }
        });


        // console.log(this.cart);

        this.$window.location.href = '/krepselis';

    }
}
